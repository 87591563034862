import * as yup from "yup";

const locationSchema = yup
  .object()
  .test("is-any-type", "Invalid location format", (value) => {
    // Check if the value is an object
    if (typeof value === "object" && value !== null) {
      // You can optionally check for specific properties here
      return true;
    }

    return false;
  });

const RegisterCallersSchema = yup.object().shape({
  name: yup.string().required("الرجاء إدخال الاسم"),
  phone: yup
    .string()
    .test("is-valid-phone", "رقم الهاتف غير صالح", (value) => {
      // Check if the number starts with "+" and has a total length of 13
      if (/^\+\d{13}$/.test(value)) {
        return true;
      }
      // Check if the number starts with "964" and has a total length of 13
      else if (/^(964)\d{11}$/.test(value) && value.startsWith("+964")) {
        return true;
      }
      // Check if the number starts with "07" and has a total length of 11
      else if (/^(077|075|078)\d{8}$/.test(value)) {
        return true;
      }

      return false;
    })
    .required("الرجاء إدخال رقم الهاتف"),
  date: yup.date().required("الرجاء إدخال التاريخ"),
  episode: yup.string().required("الرجاء أختيار البرنامج"),
  location: locationSchema.required(),
  gender: yup.string().required("الرجاء أختيار الجنس"),
});

export default RegisterCallersSchema;

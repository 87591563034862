import { useMediaQuery } from "@mui/material";
import { DateRange, StaticDateRangePicker } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { pickersLayoutClasses } from "@mui/x-date-pickers/PickersLayout";
import { PickersShortcutsItem } from "@mui/x-date-pickers/PickersShortcuts";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs, { Dayjs } from "dayjs";

const shortcutsItems: PickersShortcutsItem<DateRange<Dayjs>>[] = [
  {
    label: "Today",
    getValue: () => {
      const today = dayjs();
      return [today.startOf("day").subtract(1, "day"), today.endOf("day")];
    },
  },
  {
    label: "Last 7 Days",
    getValue: () => {
      const today = dayjs();
      return [today.subtract(7, "day"), today];
    },
  },
  {
    label: "This Week",
    getValue: () => {
      const today = dayjs();
      return [today.startOf("week"), today];
    },
  },
  {
    label: "Last Week",
    getValue: () => {
      const today = dayjs();
      const prevWeek = today.subtract(7, "day");
      return [prevWeek.startOf("week"), prevWeek.endOf("week")];
    },
  },

  {
    label: "Current Month",
    getValue: () => {
      const today = dayjs();
      return [today.startOf("month"), today];
    },
  },
  {
    label: "Last 30 Days",
    getValue: () => {
      const today = dayjs();
      return [today.subtract(30, "day"), today];
    },
  },
  {
    label: "Current Year",
    getValue: () => {
      const today = dayjs();
      return [today.startOf("year"), today];
    },
  },
  {
    label: "Last Year",
    getValue: () => {
      const today = dayjs();
      const lastYear = today.subtract(1, "year");
      return [lastYear.startOf("year"), lastYear.endOf("year")];
    },
  },
  { label: "Reset", getValue: () => [null, null] },
];

export default function BasicRangeShortcuts({ handleDateRange }: any) {
  const today = dayjs();
  const currentMonthRange: DateRange<Dayjs> = [
    today.subtract(1, "day"),
    today,
  ];

  const isSmallerScreen = useMediaQuery("(max-width: 700px)");

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={["SingleInputDateRangeField"]}>
        {!isSmallerScreen ? (
          <StaticDateRangePicker
            slotProps={{
              shortcuts: {
                items: shortcutsItems,
              },
              actionBar: { actions: [] },
            }}
            calendars={2}
            onChange={(e: DateRange<dayjs.Dayjs>) => {
              let newE = [];
              newE[0] = e[0].add(3, "hours");
              newE[1] = e[1].add(3, "hours");

              handleDateRange(newE);
            }}
            defaultValue={currentMonthRange}
          />
        ) : (
          <StaticDateRangePicker
            defaultValue={currentMonthRange}
            sx={{
              [`.${pickersLayoutClasses.contentWrapper}`]: {
                alignItems: "center",
              },
            }}
            onChange={(e: DateRange<dayjs.Dayjs>) => {
              let newE = [];
              newE[0] = e[0].add(3, "hours");
              newE[1] = e[1].add(3, "hours");

              handleDateRange(newE);
            }}
          />
        )}
      </DemoContainer>
    </LocalizationProvider>
  );
}

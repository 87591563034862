import { Box, Button, IconButton, Tooltip } from "@mui/material";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";

const CallsFormSubmit = (props) => {
  const { resetForm, handleSubmit, onSubmit, triggerAll } = props;
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <Button
          sx={{
            borderRadius: "12px",
            marginTop: "20px",
            padding: "10px 20px",
            backgroundColor: "#475D92",
            color: "white",
            width: "100%",
            ":hover": { backgroundColor: "#475D92", color: "white" },
          }}
          onClick={() => {
            triggerAll();
            handleSubmit(onSubmit)();
          }}
        >
          حفظ
        </Button>
        <Tooltip title="اعادة تعيين">
          <IconButton
            sx={{
              backgroundColor: "#D9E2FF",
              borderRadius: "50%",
              padding: "5px !important",
              height: "45px",
              width: "45px",
              marginTop: "20px",
            }}
            onClick={resetForm}
          >
            <RestartAltOutlinedIcon
              sx={{
                color: "#475D92",
              }}
            />
          </IconButton>
        </Tooltip>
      </Box>
    </>
  );
};

export default CallsFormSubmit;

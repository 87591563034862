export function formatDateAndTime(dateTimeString) {
  const date = new Date(dateTimeString);

  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  let hours = date.getHours();
  const amPm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12 || 12; // Convert 0 to 12 if needed
  const formattedHours = String(hours).padStart(2, "0");

  const minutes = String(date.getMinutes()).padStart(2, "0");

  return (
    <div>
      <span style={{ color: "blue" }}>
        {" "}
        {`${formattedHours}:${minutes}${amPm}`}{" "}
      </span>{" "}
      {` - ${month}/${day}`}
    </div>
  );
}

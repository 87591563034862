import { useContext } from "react";
import { useLocation } from "react-router";
import { Navigate, Outlet } from "react-router-dom";
import {
  GlobalStateContext,
  GlobalStateContextType,
} from "../context/GlobalStateContext";

const useAuth = () => {
  const { user } = useContext<GlobalStateContextType>(GlobalStateContext);
  return user && user.loggedIn;
};

const ProtectedRoutes = (): any => {
  const location = useLocation();
  const isAuth = useAuth();

  if (isAuth) {
    return <Outlet />;
  }
  return <Navigate to="/login" replace state={{ from: location }} />;
};

export default ProtectedRoutes;

import { Box, Grid, Typography } from "@mui/material";
import CallsForm from "../../../../components/form/CallsForm";
import Timer from "../../../../components/timer/timer";
import phone from "../../assets/phone.png";
import phone_calling from "../../assets/phone_calling.png";
import "./calls-card.css";
import CallsCardHeader from "../calls-card-header/calls-card-header";
import CallsCardForm from "../calls-card-form/calls-card-form";

const CallsCard = (props) => {
  const { formRef, methods, call, index, simNumber } = props;

  return (
    <>
      {/* <Box
        sx={{
          width: "100%",
          padding: "20px",

          borderRadius: "10px",
        }}
        className="container_bg_forms"
      >
        <Grid
          container
          display="flex"
          alignItems="center"
          ref={formRef1}
          className={call?.length > 0 ? "active-background" : ""}
          sx={{
            padding: "80px 0 0 0 !important",
          }}
        >
          <Grid item xs={6}>
            <CallsForm methods={methods} call={call} />
          </Grid>
          <Grid item xs={6}>
            <Box>
              <Typography textAlign="center" variant="h1">
                {call?.length > 0 ? (
                  <img
                    className="phone-calling"
                    width={100}
                    src={phone_calling}
                    alt=""
                  />
                ) : (
                  <img
                    style={{ opacity: 0.5 }}
                    width={100}
                    src={phone}
                    alt=""
                  />
                )}
              </Typography>
              <Typography
                textAlign="center"
                variant="h6"
                style={{
                  color: call?.length > 0 ? "green" : "black",
                }}
              >
                {call?.length > 0
                  ? call[0].State === "Up"
                    ? "تم الرد"
                    : "...يرن"
                  : "لا توجد مكالمات"}
                <h3 style={{ color: "#F24" }}>SIM 0</h3>
                <h6 style={{ color: "#0007", marginTop: "-5px" }}>
                  07508881115
                </h6>
                {call?.length > 0 ? (
                  call[0].State === "Up" ? (
                    <>
                      <Timer />
                    </>
                  ) : (
                    <></>
                  )
                ) : (
                  ""
                )}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box> */}
      <Grid item xs={12} lg={6} ref={formRef}>
        <Box className="calls-card-background">
          <CallsCardHeader call={call} index={index} simNumber={simNumber} />
          <CallsCardForm methods={methods} call={call} />
        </Box>
      </Grid>
    </>
  );
};

export default CallsCard;

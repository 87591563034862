export function convertArabicToEnglishNumber(arabicNumber) {
  const arabicNumerals = "٠١٢٣٤٥٦٧٨٩";
  const englishNumerals = "0123456789";

  const numeralMap = new Map(
    [...arabicNumerals].map((num, index) => [num, englishNumerals[index]])
  );

  const englishNumber = [...arabicNumber]
    .map((char) => numeralMap.get(char) || char)
    .join("");

  return englishNumber;
}

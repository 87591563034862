import { Box } from "@mui/material";
import { createContext, useEffect, useState } from "react";

type User = {
  loggedIn: boolean;
  username?: string;
  role?: string;
  userId?: string;
};

export type GlobalStateContextType = {
  user: User;
  setUser: React.Dispatch<React.SetStateAction<User>>;
  authentication: React.Dispatch<React.SetStateAction<string>>;
};

export const GlobalStateContext = createContext<any>({
  user: { loggedIn: false },
  setUser: () => {},
});

interface AuthProviderProps {
  children: React.ReactNode;
}

const AuthProvider = ({ children }: AuthProviderProps) => {
  let userInitial: any = localStorage.getItem("user");
  const [authentication, setAuthentication] = useState<null | string>(null);

  const [user, setUser] = useState<any>({
    loggedIn: false,
  });

  useEffect(() => {
    let currentAuthentication = localStorage.getItem("token");
    setAuthentication(currentAuthentication);
    if (authentication) {
      setUser({
        loggedIn: true,
      });
    } else {
      setUser({
        loggedIn: false,
      });
    }
  }, [userInitial, authentication]);

  if (authentication && user.loggedIn == false) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100vh",
        }}
      >
        Loading ...
      </Box>
    );
  }

  return (
    <GlobalStateContext.Provider
      value={{
        user,
        setUser,
        authentication,
      }}
    >
      {children}
    </GlobalStateContext.Provider>
  );
};

export default AuthProvider;

import { api } from "../Intercepter";

interface createEntreatyApiParams {
  callId: string;
  file: any;
}

// export const createEntreatyApi = async ({
//   callId,
//   file,
// }: createEntreatyApiParams) => {
//   const formData = new FormData();
//   formData.append("callId", callId);
//   formData.append("file", file);

//   let response = await api.post(`/entreaties/create-by-call-id`, formData);

//   if (!response) {
//     throw new Error("Error in Create Entreaty");
//   }

//   return response.data;
// };

export const createEntreatyApi = async ({
  callId,
  file,
}: createEntreatyApiParams) => {
  try {
    // Create FormData
    const formData = new FormData();

    // Append data
    formData.append("callId", callId);
    formData.append("file", file, file.name); // Make sure to include filename

    // Debug logs
    console.log("File being sent:", file);
    console.log("FormData entries:");
    for (let pair of formData.entries()) {
      console.log(pair[0], pair[1]);
    }

    // Make the request
    const response = await api.post("/entreaties/create-by-call-id", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        // Don't set Content-Type manually - axios will set it with the boundary
        // Let the browser set the Content-Type and boundary automatically
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error in createEntreatyApi:", error);
    throw error;
  }
};

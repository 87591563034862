import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  FormControl,
  FormHelperText,
  IconButton,
  TextField,
} from "@mui/material";
import {
  GlobalStateContext,
  GlobalStateContextType,
} from "../../context/GlobalStateContext";
import { Snackbar } from "@mui/material";
import { schema } from "./schema/LoginSchema";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import "./login.css";
import { useMutation } from "react-query";
import { api } from "../../api/Intercepter";
import { useSnackbar } from "notistack";

type LoginFormType = {
  username: string;
  password: string;
};

const Login = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const { setUser, user } =
    useContext<GlobalStateContextType>(GlobalStateContext);

  const { mutateAsync, data, isError, error, isSuccess } = useMutation(
    async (data: any) => {
      let res = await api.post(
        `${import.meta.env.VITE_SOME_KEY_BASE_URL}/user-auth`,
        {
          userName: data?.username,
          password: data?.password,
        }
      );
      return res?.data;
    }
  );

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<LoginFormType>({ resolver: yupResolver(schema) });

  const onSubmit: SubmitHandler<LoginFormType> = async (data) => {
    mutateAsync({ ...data });
    // if (data.username === "admin" && data.password === "admin") {
    //   setUser({
    //     loggedIn: true,
    //   });
    //   localStorage.setItem("user", JSON.stringify(data));
    //   localStorage.setItem(
    //     "token",
    //     "ocdugicdcw76i3276rb276db7n2gudhfnduyfbsuyfndsyuftdbnksjgnfukcgdbkuydsbfg6rb376r276n38d7f372fn327gdkj2fi763fbnkud2gnuyfkd27ibfniu32gd3u2m.d2ou3ouhd23dgd3ih23iud/d233u3hid2gi2hi3gd83g38ydg"
    //   );
    //   navigate("/");
    // } else {
    //   setOpen(true);
    // }
  };

  const { state } = useLocation();

  useEffect(() => {
    if (user.loggedIn) {
      navigate(state?.from?.pathname ?? "/");
    }
  }, [user.loggedIn]);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const [showPassword, setShowPassword] = React.useState(false);

  useEffect(() => {
    if (isError) {
      let errMsh = error as any;
      enqueueSnackbar(errMsh?.response?.data?.message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  }, [isError]);

  useEffect(() => {
    if (isSuccess) {
      navigate("/");
      localStorage.setItem("user", JSON.stringify(data.user));
      localStorage.setItem("token", data.accessToken);
      setUser({ loggedIn: true });
    }
  }, [isSuccess]);

  return (
    <div className="login__page row">
      <div className="col-6 right-side">
        <div
          style={{
            backgroundColor: "black",
            opacity: "0.3",
            width: "100%",
            height: "100%",
          }}
        ></div>
      </div>
      <div className="col-6 left-side col-md-12">
        <section style={{ maxWidth: "600px" }} className="containter__form">
          <h1>راديو الغد</h1>
          <div
            style={{
              fontSize: "14px",
              fontWeight: "700",
              color: "#888",
              marginBottom: "70px",
            }}
          >
            مرحبا بكم في راديو الغد, من فضلك قم بتسجيل الدخول
          </div>
          <form
            onSubmit={handleSubmit((data) => {
              onSubmit(data);
            })}
          >
            <FormControl sx={{ width: "100%" }} variant="standard">
              <label
                style={{
                  fontSize: "14px",
                  marginBottom: "5px",
                  fontWeight: "700",
                }}
                htmlFor="bootstrap-input1"
              >
                اسم المستخدم
                <span style={{ color: "red" }}>*</span>
              </label>
              <TextField
                className={errors?.username?.message && "error"}
                placeholder="ادخل اسم المستخدم"
                {...register("username")}
              />
              <FormHelperText sx={{ color: "red" }}>
                {errors?.username?.message}
              </FormHelperText>
            </FormControl>
            <FormControl
              sx={{ width: "100%", marginTop: "12px", position: "relative" }}
              variant="standard"
            >
              <label
                style={{
                  fontSize: "14px",
                  marginBottom: "5px",
                  fontWeight: "700",
                }}
                htmlFor="bootstrap-input1"
              >
                كلمة المرور
                <span style={{ color: "red" }}>*</span>
              </label>
              <TextField
                className={errors?.password?.message && "error"}
                placeholder="ادخل كلمة المرور"
                type={showPassword ? "text" : "password"}
                {...register("password")}
              />
              <IconButton
                style={{ position: "absolute", top: "38px", left: "10px" }}
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <VisibilityIcon className="show-password-icon" />
                ) : (
                  <VisibilityOffIcon className="show-password-icon" />
                )}
              </IconButton>
              <FormHelperText sx={{ color: "red" }}>
                {errors?.password?.message}
              </FormHelperText>
            </FormControl>
            <input
              style={{ marginTop: "10px" }}
              type="submit"
              className="submit-btn"
              value="تسجيل الدخول"
            />
            <>
              <Snackbar
                open={open}
                autoHideDuration={3000}
                onClose={handleClose}
              >
                <Alert
                  onClose={handleClose}
                  severity="error"
                  variant="filled"
                  sx={{ width: "100%" }}
                >
                  اسم المستخدم او كلمة المرور غير صحيحة
                </Alert>
              </Snackbar>
            </>
          </form>
        </section>
      </div>
    </div>
  );
};

export default Login;

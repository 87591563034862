import { Autocomplete } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";

export const CustomTextField = styled("input")(
  () => `
  transition: 0.2s ease-in-out all;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 10px 12px;
  border-radius: 6px;
  border: 1px solid #E7EBF0;
  background-color: #000B1808;
  outline: 0;

  &:hover {
    border-color: #443DF6;
  }

  &:focus {
    border-color: #443DF6;
    box-shadow: 0 0 0 3px rgba(68, 61, 246, 0.26);
    -webkit-box-shadow: 0 0 0 3px rgba(68, 61, 246, 0.26);
    -moz-box-shadow: 0 0 0 3px rgba(68, 61, 246, 0.26);
  }

  &.error {
    border-color: red;
    box-shadow: 0 0 0 3px rgba(255, 0, 0, 0.4);
    -webkit-box-shadow: 0 0 0 3px rgba(255, 0, 0, 0.4);
    -moz-box-shadow: 0 0 0 3px rgba(255, 0, 0, 0.4);
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }

  @media screen and (max-width: 600px) {
    font-size: 0.75rem;
    padding: 8px 10px;
  }
  
  @media screen and (max-width: 400px) {
    font-size: 0.625rem;
    padding: 6px 8px;
  }
`
);
// export const CustomTextField = styled(TextField)(({ theme }) => ({
//   "& .MuiOutlinedInput-root": {
//     transition: "0.2s ease-in-out all",

//     borderRadius: "6px",
//     backgroundColor: "#000B1808",
//     "& .MuiOutlinedInput-notchedOutline": {
//       border: "none",
//     },
//   },
//   "& .MuiOutlinedInput-root.Mui-focused": {
//     border: `1px solid ${alpha(theme.palette.primary.main, 1)}`,
//     boxShadow: `${alpha(theme.palette.primary.main, 0.2)} 0 0 0 0.2rem`,
//   },
//   "& .MuiOutlinedInput-root.Mui-error": {
//     border: `1px solid red`,
//     boxShadow: `#F005 0 0 0 0.2rem`,
//   },

//   "& .MuiInputLabel-root": {
//     display: "none",
//     "&:focus": {
//       boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
//       borderColor: theme.palette.primary.main,
//     },
//   },
//   "& .MuiOutlinedInput-input": {
//     fontSize: "0.9rem",
//     padding: "11px 14px",
//   },

//   [theme.breakpoints.down("sm")]: {
//     "& .MuiOutlinedInput-input": {
//       fontSize: "0.8rem",
//       padding: "10px 12px",
//     },
//   },
// }));

export const CustomAutoComplete = styled(Autocomplete)(({ theme }) => ({
  " .MuiOutlinedInput-root.MuiInputBase-sizeSmall": {
    backgroundColor: "#FFF",
  },
  "& .MuiOutlinedInput-root": {
    transition: "0.2s ease-in-out all",

    borderRadius: "6px",
    backgroundColor: "#000B1808",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
  "& .MuiOutlinedInput-root.Mui-focused": {
    border: `1px solid ${alpha(theme.palette.primary.main, 1)}`,
    boxShadow: `${alpha(theme.palette.primary.main, 0.2)} 0 0 0 0.2rem`,
  },
  "& .MuiOutlinedInput-root.Mui-error": {
    border: `1px solid red`,
    boxShadow: `#F005 0 0 0 0.2rem`,
  },

  "& .MuiInputLabel-root": {
    display: "none",
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
  "& .MuiOutlinedInput-input": {
    fontSize: "0.9rem",
    padding: "11px 14px",
  },

  [theme.breakpoints.down("sm")]: {
    "& .MuiOutlinedInput-input": {
      fontSize: "0.8rem",
      padding: "10px 12px",
    },
  },
}));

import * as yup from "yup";

export const schema = yup
  .object({
    username: yup
      .string()
      .transform((value) => value.trim())
      .required("يجب ادخال اسم المستخدم"),
    password: yup.string().required("يجب ادخال كلمة المرور"),
  })
  .required();

import "./date-range-picker-mui.scss";

const DateRangePickerMui = ({
  startDate = "DD/MM/YYYY",
  endDate = "DD/MM/YYYY",
  handleClick,
  style
}: any) => {
  return (
    <div className="input-range" style={style} onClick={handleClick}>{`${startDate?.slice(
      0,
      10
    )} - ${endDate?.slice(0, 10)}`}</div>
  );
};

export default DateRangePickerMui;

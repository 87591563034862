import { IconButton, Tooltip } from "@mui/material";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import { createEntreatyApi } from "../../../api/create-entreaty-api/create-entreaty-api";
import { useMutation } from "react-query";
import { useState } from "react";
import CreateEntreatyModal from "../create-entreaty-modal/create-entreaty-modal";

const EntreatyButton = ({ callId }) => {
  const [openCreateEntreatyModal, setOpenCreateEntreatyModal] = useState(false);

  return (
    <>
      <Tooltip title={"تحويل مناشدة"}>
        <IconButton
          sx={{
            backgroundColor: "#D9E2FF",
            borderRadius: "50%",
            padding: "5px !important",
            height: "35px",
            width: "35px",
          }}
          //   onClick={() => handleToggleEntreaty(callId)}
          onClick={() => setOpenCreateEntreatyModal(true)}
        >
          <ReceiptLongOutlinedIcon
            sx={{
              color: "#475D92",
            }}
          />
        </IconButton>
      </Tooltip>
      <CreateEntreatyModal
        openModal={openCreateEntreatyModal}
        handleClose={() => setOpenCreateEntreatyModal(false)}
        callId={callId}
      />
    </>
  );
};

export default EntreatyButton;

import React from "react";
import "./pulse.css";
import { Box } from "@mui/material";
const Pulse = ({ color }) => {
  return (
    <Box
      sx={{
        ":before": { borderColor: `${color} !important` },
        ":after": { borderColor: `${color} !important` },
      }}
      className="loader"
    ></Box>
  );
};

export default Pulse;

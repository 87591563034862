import { Box } from "@mui/material";
import React from "react";
import RegisterCallersForm from "../../components/register-callers-form/RegisterCallersForm";

const RegisterUsers = () => {
  return (
    <Box sx={{ width: "100%", minHeight: "100vh", backgroundColor: "#F5F7FA", padding: "100px" }}>
      <RegisterCallersForm />
    </Box>
  );
};

export default RegisterUsers;

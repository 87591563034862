import { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  ClickAwayListener,
  IconButton,
  LinearProgress,
  MenuItem,
  Pagination,
  Select,
  TableContainer,
  TextField,
  Tooltip,
} from "@mui/material";
import { useQuery } from "react-query";
import { getAllPhoneBook } from "../../api/phone-book-api/getAllPhoneBook";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { api } from "../../api/Intercepter";
import ClientsAutoComplete from "./components/ClientsAutoComplete";
import { CustomTextField } from "../../components/inputs/input/BootStrapInput";
import ManIcon from "@mui/icons-material/Man";
import WomanIcon from "@mui/icons-material/Woman";
import { formatDateAndTime } from "../../utils/formatDateAndTime";
import { convertArabicToEnglishNumber } from "../../utils/convertArabicToEnglish";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

const PhoneBook = () => {
  const [search, setSearch] = useState<string>("");
  const [page, setPage] = useState<number>(0);
  const [isClientNameNull, setIsClientNameNull] = useState(undefined);
  const [genderNull, setGenderNull] = useState(undefined);
  const limit: number = 100;

  const handleSearch = (value) => {
    let cleanedPhoneNumber = convertArabicToEnglishNumber(value)?.replace(
      /^07/,
      "7"
    );
    return cleanedPhoneNumber.replace(/^(\+964|964)/, "");
  };

  const {
    data: usersData,
    refetch,
    isLoading,
  } = useQuery({
    queryKey: [
      `users-page-${page}-limit-${limit}-${search}-${isClientNameNull}-${genderNull}`,
    ],
    queryFn: async () => {
      const data = await getAllPhoneBook({
        currentPage: page,
        limit: limit,
        search: handleSearch(search),
        clientName: isClientNameNull,
        genderNull: genderNull,
      });
      return data;
    },
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    refetch();
  }, []);

  const [editingRowIndex, setEditingRowIndex] = useState(null);
  const [editedFullName, setEditedFullName] = useState("");
  const [editedGender, setEditedGender] = useState("");
  const [editedAutoComplete, setEditedAutoComplete] = useState<any>();
  const [editedPhoneNumber, setEditedPhoneNumber] = useState<any>();
  const [editedNotes, setEditedNotes] = useState("");

  const handleDoubleClick = (index, fullName, gender, phone, notes) => {
    setEditingRowIndex(index);
    setEditedFullName(fullName);
    setEditedGender(gender);
    setEditedPhoneNumber(phone);
    setEditedNotes(notes);
  };

  const handleFullNameChange = (e) => {
    setEditedFullName(e.target.value);
  };

  const handleGenderChange = (e) => {
    setEditedGender(e.target.value);
  };

  const handleNotesChange = (e) => {
    setEditedNotes(e.target.value);
  };

  const handleSubmitData = (index, userId) => {
    api
      .patch(`${import.meta.env.VITE_SOME_KEY_BASE_URL}/clients/${userId}`, {
        fullName: editedFullName,
        neighborhoodId: editedAutoComplete.neighborhood_id ?? undefined,
        cityId: editedAutoComplete.city_id ?? undefined,
        subCityId: editedAutoComplete.sub_city_id ?? undefined,
        governorateId: editedAutoComplete.governorate_id ?? undefined,
        phoneNumber: editedPhoneNumber[0]?.phoneNumber,
        gender: editedGender,
        notes: editedNotes,
      })
      .then((response) => {
        refetch();
      });

    setEditedAutoComplete("");
    setEditingRowIndex(null);
    setEditedFullName("");
    setEditedNotes("");
  };

  const [isInteracting, setIsInteracting] = useState(false);

  const handleInteractionStart = () => setIsInteracting(true);
  const handleInteractionEnd = () => setIsInteracting(false);

  const handleClickAway = () => {
    if (!isInteracting) {
      handleSubmitData(editingRowIndex, usersData.array[editingRowIndex].id);
    }
  };

  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundColor: "#F2F5FA",
        padding: "20px 40px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          gap: "10px",
          alignItems: "center",
        }}
      >
        <CustomTextField
          placeholder="البحث"
          onChange={(e) => setSearch(e.target.value)}
          sx={{ direction: "rtl", textAlign: "left", backgroundColor: "white" }}
          disabled={isClientNameNull}
        />
        <Button
          sx={{
            backgroundColor:
              isClientNameNull === undefined ? "#FFF" : "#475D92",
            color: isClientNameNull === undefined ? "#475D92" : "#FFF",
            borderRadius: "6px",
            "&:hover": {
              backgroundColor:
                isClientNameNull === undefined ? "#FFF" : "#475D92",
              color: isClientNameNull === undefined ? "#475D92" : "#FFF",
            },
          }}
          onClick={() => {
            if (isClientNameNull === undefined) {
              setIsClientNameNull("name_null");
            } else {
              setIsClientNameNull(undefined);
            }
          }}
        >
          بدون اسماء
        </Button>
        <Button
          sx={{
            backgroundColor: genderNull === undefined ? "#FFF" : "#475D92",
            color: genderNull === undefined ? "#475D92" : "#FFF",
            borderRadius: "6px",
            "&:hover": {
              backgroundColor: genderNull === undefined ? "#FFF" : "#475D92",
              color: genderNull === undefined ? "#475D92" : "#FFF",
            },
          }}
          onClick={() => {
            if (genderNull === undefined) {
              setGenderNull("gender_null");
            } else {
              setGenderNull(undefined);
            }
          }}
        >
          بدون جنس
        </Button>
      </Box>
      <div style={{ backgroundColor: "white", borderRadius: "12px" }}>
        <div
          style={{ width: "100%", marginTop: "20px", paddingBottom: "30px" }}
        >
          <TableContainer
            sx={{
              width: "100%",
              borderRadius: "6px",
            }}
          >
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead sx={{ backgroundColor: "#DEE6FA" }}>
                <TableRow>
                  <TableCell>ت</TableCell>
                  <TableCell>الاسم</TableCell>
                  <TableCell>الجنس</TableCell>
                  <TableCell>رقم الهاتف</TableCell>
                  <TableCell>المنطقة</TableCell>
                  <TableCell>وقت الاتصال</TableCell>
                  <TableCell>مللاحظات</TableCell>
                  <TableCell>تعديل</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {usersData?.array?.map((row, index) => (
                  <ClickAwayListener key={row.id} onClickAway={handleClickAway}>
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        tr: { cursor: "pointer" },
                      }}
                      onDoubleClick={() =>
                        handleDoubleClick(
                          index,
                          row.fullName,
                          row.gender,
                          row.phones,
                          row.notes
                        )
                      }
                    >
                      <TableCell component="th" scope="row">
                        {index + 1}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{ width: "20%" }}
                      >
                        {editingRowIndex === index ? (
                          <TextField
                            type="text"
                            value={editedFullName}
                            onChange={handleFullNameChange}
                            size="small"
                            onFocus={handleInteractionStart} // Track interaction
                            onBlur={handleInteractionEnd} // Track interaction end
                          />
                        ) : (
                          <span>{row.fullName}</span>
                        )}
                      </TableCell>
                      <TableCell component="th" sx={{ width: "5%" }}>
                        {editingRowIndex === index ? (
                          <Select
                            value={editedGender}
                            onChange={handleGenderChange}
                            label="Gender"
                            size="small"
                            onOpen={handleInteractionStart} // Dropdown opened
                            onClose={handleInteractionEnd} // Dropdown closed
                          >
                            <MenuItem value="male">ذكر</MenuItem>
                            <MenuItem value="female">انثى</MenuItem>
                          </Select>
                        ) : (
                          <>
                            {row.gender === "male" ? (
                              <Tooltip title="ذكر">
                                <ManIcon
                                  sx={{
                                    backgroundColor: "#629FC1",
                                    color: "white",
                                    borderRadius: "4px",
                                  }}
                                />
                              </Tooltip>
                            ) : row.gender === "female" ? (
                              <Tooltip title="انثى">
                                <WomanIcon
                                  sx={{
                                    backgroundColor: "#BF62C1",
                                    color: "white",
                                    borderRadius: "4px",
                                  }}
                                />
                              </Tooltip>
                            ) : (
                              <Tooltip title="غير محدد">
                                <HelpCenterIcon
                                  sx={{
                                    backgroundColor: "#555555",
                                    color: "white",
                                    borderRadius: "4px",
                                  }}
                                />
                              </Tooltip>
                            )}
                          </>
                        )}
                      </TableCell>
                      <TableCell component="th">
                        {row?.phones?.map((phone, index) => {
                          return (
                            <div key={index} style={{ direction: "ltr" }}>
                              {phone.phoneNumber}
                            </div>
                          );
                        })}
                      </TableCell>
                      <TableCell component="th" sx={{ width: "20%" }}>
                        {editingRowIndex === index ? (
                          <ClientsAutoComplete
                            data={row}
                            editedAutoComplete={editedAutoComplete}
                            setEditedAutoComplete={setEditedAutoComplete}
                            locations={usersData?.locations}
                            handleInteractionStart={handleInteractionStart}
                            handleInteractionEnd={handleInteractionEnd}
                          />
                        ) : (
                          <>
                            {usersData?.locations[row?.id]?.neighborhood_name ??
                              usersData?.locations[row?.id]?.sub_city_name ??
                              usersData?.locations[row?.id]?.city_name ??
                              "لا يوجد"}
                          </>
                        )}
                      </TableCell>
                      <TableCell component="th" sx={{ width: "15%" }}>
                        {formatDateAndTime(row.createdAt)}
                      </TableCell>
                      <TableCell component="th" sx={{ width: "100%" }}>
                        {editingRowIndex === index ? (
                          <TextField
                            multiline
                            sx={{ width: "100%" }}
                            value={editedNotes}
                            onChange={handleNotesChange}
                            size="small"
                            onFocus={handleInteractionStart} // Track interaction
                            onBlur={handleInteractionEnd} // Track interaction end
                          />
                        ) : (
                          <span>{row.notes}</span>
                        )}
                      </TableCell>
                      {editingRowIndex === index ? (
                        <TableCell>
                          <Button
                            onClick={() => handleSubmitData(index, row.id)}
                          >
                            حفظ
                          </Button>
                        </TableCell>
                      ) : (
                        <TableCell>
                          <IconButton
                            onClick={() =>
                              handleDoubleClick(
                                index,
                                row.fullName,
                                row.gender,
                                row.phones,
                                row.notes
                              )
                            }
                          >
                            <EditOutlinedIcon />
                          </IconButton>
                        </TableCell>
                      )}
                    </TableRow>
                  </ClickAwayListener>
                ))}
              </TableBody>
            </Table>
            {isLoading && (
              <LinearProgress
                color="primary"
                data-testid="product-member-loader"
              />
            )}
            <Pagination
              sx={{ marginTop: "10px", marginLeft: "20px" }}
              count={Math.ceil(usersData?.count / limit)}
              onChange={(e, value) => setPage(value - 1)}
              variant="outlined"
              shape="rounded"
            />
          </TableContainer>
        </div>
      </div>
    </div>
  );
};

export default PhoneBook;

import { Box, Typography } from "@mui/material";
import CallEndIcon from "@mui/icons-material/CallEnd";
import RingVolumeIcon from "@mui/icons-material/RingVolume";
import CallIcon from "@mui/icons-material/Call";
import "./calls-card-header.css";
import Pulse from "../../../../components/pulse/pulse";

const CallsCardHeader = (props) => {
  const { call, index, simNumber } = props;

  const handleCallStatus = (call) => {
    return call?.length > 0 ? (call[0].State === "Up" ? 2 : 1) : 0;
  };

  return (
    <Box className="calls-card-header">
      <Box className="calls-card-icon-container">
        <Box
          sx={{
            padding: "25px",
            backgroundColor:
              handleCallStatus(call) === 0
                ? "#E2E8F6"
                : handleCallStatus(call) === 1
                ? "#0075BF"
                : "#20932A",
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#8895B6",
            position: "relative",
          }}
        >
          {handleCallStatus(call) === 1 ? (
            <Pulse color={"#0075BF"} />
          ) : handleCallStatus(call) === 2 ? (
            <Pulse color={"#20932A"} />
          ) : null}
          {handleCallStatus(call) === 0 ? (
            <CallEndIcon sx={{ fontSize: "2.7rem", zIndex: 10 }} />
          ) : handleCallStatus(call) === 1 ? (
            <RingVolumeIcon
              sx={{ fontSize: "2.7rem", color: "#FFF", zIndex: 10 }}
            />
          ) : handleCallStatus(call) === 2 ? (
            <CallIcon sx={{ fontSize: "2.7rem", color: "#FFF", zIndex: 10 }} />
          ) : null}
        </Box>
      </Box>
      <Box className="calls-card-status">
        <Typography className="calls-card-status-text">
          {handleCallStatus(call) === 0 ? (
            <>لا توجد مكالمات</>
          ) : handleCallStatus(call) === 1 ? (
            <>يرن </>
          ) : handleCallStatus(call) === 2 ? (
            <>تم الرد</>
          ) : null}
        </Typography>
        <Typography sx={{ color: "#3E528255" }}>00:00:00</Typography>
      </Box>
      <Box className="calls-card-sim">
        <Typography className="calls-card-sim-number">Sim {index}</Typography>
        <Typography className="calls-card-sim-phone">{simNumber}</Typography>
      </Box>
    </Box>
  );
};

export default CallsCardHeader;

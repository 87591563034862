import "./drag-drop-file.scss";

const DragDropFile = ({ handleFileChange }) => {
  return (
    <>
      <div className="drop-file-input">
        <div className="drop-file-input__label">
          Drag & Drop your audio here
        </div>
        <input
          type="file"
          id="profilePicture"
          onChange={(e) => handleFileChange(e.target.files[0])}
        />
      </div>
    </>
  );
};

export default DragDropFile;

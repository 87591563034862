import {
  Box,
  Button,
  FormControl,
  InputAdornment,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import AdUnitsOutlinedIcon from "@mui/icons-material/AdUnitsOutlined";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import ManIcon from "@mui/icons-material/Man";
import WomanIcon from "@mui/icons-material/Woman";
import { useMutation } from "react-query";
import { api } from "../../../../api/Intercepter";
import { useEffect } from "react";
import { useSnackbar } from "notistack";

const sendMessages = async ({ data }) => {
  let response = await api.post("/messages/send-custom-message", {
    message: `حمل تطبيق راديو الغد عبر الرابط التالي:
    qsr.ac/alghad-app`,
    phoneNumber: data.phoneNumber,
  });

  return response?.data;
};

const CallsFormPhoneField = ({
  hasApp,
  register,
  setValue,
  gender,
  setGender,
  errors,
  phoneNumber,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { mutateAsync, isSuccess } = useMutation((data: any) => {
    return sendMessages({ data });
  });

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar("تم ارسال الرسالة بنجاح", {
        variant: "success",
      });
    }
  }, [isSuccess]);

  const handlePhoneNumber = (value) => {
    let cleanedPhoneNumber = value.replace(/^(07|\+96407|96407)/, "+9647");
    let cleanedPhoneNumberTo964 = cleanedPhoneNumber.replace(
      /^(\+964|964)/,
      "+964"
    );

    return cleanedPhoneNumberTo964;
  };

  return (
    <>
      <Box className="calls-card-form-phone-field">
        <FormControl sx={{ width: "100%" }}>
          <TextField
            placeholder="رقم الموبايل"
            label="رقم الموبايل"
            size="medium"
            {...register("phone", {
              required: true,
            })}
            sx={{
              ".muirtl-nugxp0-MuiInputBase-root-MuiOutlinedInput-root": {
                borderRadius: "12px",
              },
            }}
            error={!!errors[`phone`]}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AdUnitsOutlinedIcon />
                </InputAdornment>
              ),
            }}
            disabled
          />
        </FormControl>
        <Button
          disabled={hasApp}
          className="calls-card-form-send-app"
          startIcon={<SendOutlinedIcon />}
          sx={{ fontSize: "12px", padding: "17px 0px", borderRadius: "12px" }}
          onClick={() => {
            mutateAsync({
              phoneNumber: handlePhoneNumber(phoneNumber),
            });
          }}
        >
          {hasApp ? <Box>يمتلك تطبيق</Box> : <Box>ارسال رابط التطبيق</Box>}
        </Button>
      </Box>
      <Box className="calls-card-form-name-field">
        <FormControl sx={{ width: "100%", marginTop: "20px" }}>
          <TextField
            placeholder="الاسم"
            label="الاسم"
            size="medium"
            {...register("name", { required: true })}
            error={!!errors[`name`]}
            sx={{
              ".muirtl-nugxp0-MuiInputBase-root-MuiOutlinedInput-root": {
                borderRadius: "12px",
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PersonOutlineOutlinedIcon />
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
        <ToggleButtonGroup
          exclusive
          className="calls-card-form-gender"
          sx={{
            border: !!errors[`gender`] && "1px solid red",
            borderRadius: "12px",
          }}
          value={gender}
        >
          <ToggleButton
            sx={{
              width: "100%",
              borderRadius: "12px",
              padding: "15px 0",
            }}
            {...register("gender", { required: true })}
            className={`${
              gender === "male" ? "gov-shortcut-btn-active" : "gender-btn"
            }`}
            value={"male"}
            onClick={() => {
              setGender("male");
              setValue("gender", "male");
            }}
          >
            <ManIcon /> ذكر
          </ToggleButton>
          <ToggleButton
            sx={{
              width: "100%",
              borderRadius: "12px",
              padding: "15px 0",
            }}
            className={`${
              gender === "female" ? "gov-shortcut-btn-active" : "gender-btn"
            }`}
            value={"female"}
            onClick={() => {
              setGender("female");
              setValue("gender", "female");
            }}
          >
            <WomanIcon /> انثى
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
    </>
  );
};

export default CallsFormPhoneField;

import { SubmitHandler } from "react-hook-form";
import { Box } from "@mui/material";
import CallsFormPhoneField from "./calls-form-phone-field";
import CallsFormSubmit from "./calls-form-submit";
import { useEffect, useState } from "react";
import CallsFormRegionField from "./calls-form-region-field";
import { api } from "../../../../api/Intercepter";
import "./calls-card-form.css";

const CallsCardForm = ({ methods, call }) => {
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    trigger,
    formState: { errors },
  }: any = methods;

  const [hasApp, setHasApp] = useState(false);
  const [governorateIdParams, setGovernorateIdParams] = useState(undefined);
  const [gender, setGender] = useState("");
  const [isExistUser, setIsExistUser] = useState(false);
  const [userId, setUserId] = useState<number>();
  const [autoCompleteData, setAutoCompleteData] = useState<any>({
    neighborhood_id: 0,
    neighborhood_name: "",
    city_id: 0,
    city_name: "",
    governorate_id: 0,
    governorate_name: "",
    sub_city_id: 0,
    sub_city_name: "",
  });

  const onSubmit: SubmitHandler<any> = async (data) => {
    let cleanedPhoneNumber = data?.phone.replace(
      /^(07|\+96407|96407)/,
      "+9647"
    );
    let cleanedPhoneNumberTo964 = cleanedPhoneNumber.replace(
      /^(\+964|964)/,
      "+964"
    );

    if (isExistUser) {
      await api
        .patch(`${import.meta.env.VITE_SOME_KEY_BASE_URL}/clients/${userId}`, {
          fullName: data.name,
          neighborhoodId: data?.city?.neighborhood_id,
          subCityId: data?.city?.sub_city_id,
          cityId: data?.city?.city_id,
          phoneNumber: String(cleanedPhoneNumberTo964),
          gender: data?.gender,
          notes: data?.info,
        })
        .catch((err) => console.log(err));
    } else {
      await api
        .post(`${import.meta.env.VITE_SOME_KEY_BASE_URL}/clients`, {
          fullName: data.name,
          neighborhoodId: data?.city?.neighborhood_id,
          subCityId: data?.city?.sub_city_id,
          cityId: data?.city?.city_id,
          phoneNumber: String(cleanedPhoneNumberTo964),
          gender: data?.gender,
          notes: data?.info,
        })
        .catch((err) => console.log(err));
    }

    resetForm();
  };

  const triggerAll = () => {
    trigger(["phone", "city", "name", "gender", "info"]);
  };

  const resetForm = () => {
    setValue("phone", "");
    setValue("city", "");
    setValue("name", "");
    setValue("gender", null);
    setValue("info", "");
    setHasApp(false);
    setGovernorateIdParams(undefined);
    setIsExistUser(false);
    setGender("");
    setAutoCompleteData({
      neighborhood_id: 0,
      neighborhood_name: "",
      city_id: 0,
      city_name: "",
      governorate_id: 0,
      governorate_name: "",
      sub_city_id: 0,
      sub_city_name: "",
    });
  };

  useEffect(() => {
    resetForm();
  }, []);

  const handleSearch = (value) => {
    let cleanedPhoneNumber = value?.replace(/^07/, "7");
    return cleanedPhoneNumber.replace(/^(\+964|964)/, "");
  };

  const getGovernorateByCity = async (cityId) => {
    let response: any = await api.get(
      `${import.meta.env.VITE_SOME_KEY_BASE_URL_LOCATION_MAKER}/city/${cityId}`
    );

    return response?.data;
  };

  useEffect(() => {
    const fetchData = async () => {
      if (call && call[0]?.phone !== getValues("phone")) {
        resetForm();
        setValue("phone", call[0]?.phone);
        api
          .get(
            `${
              import.meta.env.VITE_SOME_KEY_BASE_URL
            }/clients/phone/${handleSearch(
              call[0]?.phone
            )}?relations[]=appClient`
          )
          .then((response) => {
            setIsExistUser(true);
            setHasApp(response?.data?.appClient ?? false);
            setUserId(response?.data?.id);
            const locationData = response?.data?.location;

            const location = locationData[Object.keys(locationData)[0]];

            async function handleSetGovernorate() {
              try {
                const result = await getGovernorateByCity(
                  response?.data?.cityId
                );
                setGovernorateIdParams(result?.governorateId);
              } catch (error) {
                console.error("Error:", error);
              }
            }

            try {
              handleSetGovernorate();
            } catch (error) {
              console.log(error);
            }

            setValue("name", response?.data?.fullName ?? "");
            setValue("gender", (response?.data?.gender || null) ?? null);
            setGender(response?.data?.gender || null);
            setValue("info", response?.data?.notes ?? "");
            setValue("city", {
              neighborhood_id: location?.neighborhood_id,
              neighborhood_name: location?.neighborhood_name,
              city_id: location?.city_id,
              city_name: location?.city_name,
              governorate_id: location?.governorate_id,
              governorate_name: location?.governorate_name,
              sub_city_id: location?.sub_city_id,
              sub_city_name: location?.sub_city_name,
            });
            setAutoCompleteData({
              neighborhood_id: location?.neighborhood_id,
              neighborhood_name: location?.neighborhood_name,
              city_id: location?.city_id,
              city_name: location?.city_name,
              governorate_id: location?.governorate_id,
              governorate_name: location?.governorate_name,
              sub_city_id: location?.sub_city_id,
              sub_city_name: location?.sub_city_name,
            });
          })
          .catch(() => {
            setValue("city", "");
            setValue("name", "");
            setValue("gender", null);
            setValue("info", "");
            setIsExistUser(false);
          });
      }
    };
    fetchData();
  }, [call]);

  return (
    <Box className="calls-card-form">
      <CallsFormPhoneField
        hasApp={hasApp}
        register={register}
        setValue={setValue}
        gender={gender}
        setGender={setGender}
        errors={errors}
        phoneNumber={getValues("phone")}
      />
      <CallsFormRegionField
        governorateIdParams={governorateIdParams}
        setGovernorateIdParams={setGovernorateIdParams}
        autoCompleteData={autoCompleteData}
        setAutoCompleteData={setAutoCompleteData}
        register={register}
        errors={errors}
        setValue={setValue}
      />
      <CallsFormSubmit
        resetForm={resetForm}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        triggerAll={triggerAll}
      />
    </Box>
  );
};

export default CallsCardForm;

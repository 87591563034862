import { useEffect, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { api } from "../../../api/Intercepter";

const HistoryAutoComplete = ({
  data,
  editedAutoComplete,
  setEditedAutoComplete,
  locations,
}) => {
  const [options, setOptions] = useState([]);

  const handleSteps = (value: any) => {
    setOptions([]);
    api
      .get(
        `${
          import.meta.env.VITE_SOME_KEY_BASE_URL_LOCATION_MAKER
        }/auto-complete/${value}`
      )
      .then((response) => {
        if (response.status === 200) {
          setOptions(response?.data);
        }
      });
  };

  useEffect(() => {
    const structureLocation = {
      city_id: locations[data?.id]?.city_id,
      city_name: locations[data?.id]?.city_name,
      governorate_name: locations[data?.id]?.governorate_name,
      governorate_id: locations[data?.id]?.governorate_id,
      sub_city_id: locations[data?.id]?.sub_city_id,
      sub_city_name: locations[data?.id]?.sub_city_name,
      neighborhood_id: locations[data?.id]?.neighborhood_id,
      neighborhood_name: locations[data?.id]?.neighborhood_name,
    };

    setEditedAutoComplete(structureLocation);
  }, [data]);

  // const getOptionLabel = (option) => {
  //   let governorate_name = option?.governorate_name;
  //   let city_name = option?.city_name;
  //   let sub_city_name = option?.sub_city_name;
  //   let neighborhood_name = option?.neighborhood_name;

  //   if (governorate_name && city_name && sub_city_name && neighborhood_name) {
  //     return `${governorate_name} / ${city_name} / ${sub_city_name} / ${neighborhood_name}`;
  //   } else if (city_name && sub_city_name && neighborhood_name) {
  //     return `${city_name} / ${sub_city_name} / ${neighborhood_name}`;
  //   } else if (sub_city_name && neighborhood_name) {
  //     return `${sub_city_name} / ${neighborhood_name}`;
  //   } else if (neighborhood_name) {
  //     return `${neighborhood_name}`;
  //   } else if (neighborhood_name) {
  //     return `${neighborhood_name}`;
  //   } else if (sub_city_name) {
  //     return `${sub_city_name}`;
  //   } else if (city_name) {
  //     return `${city_name}`;
  //   } else if (governorate_name) {
  //     return `${governorate_name}`;
  //   } else {
  //     return "";
  //   }
  // };

  function formatLocation(...locationComponents) {
    const nonEmptyComponents = locationComponents
      .filter((component) => component)
      ?.reverse();

    return nonEmptyComponents.join(" / ");
  }

  function getOptionLabel(option) {
    let governorate_name = option?.governorate_name;
    let city_name = option?.city_name;
    let sub_city_name = option?.sub_city_name;
    let neighborhood_name = option?.neighborhood_name;

    const formattedLocation = formatLocation(
      governorate_name,
      city_name,
      sub_city_name,
      neighborhood_name
    );

    return formattedLocation;
  }

  return (
    <div>
      <Autocomplete
        sx={{ minWidth: "250px" }}
        size="small"
        disablePortal
        options={options}
        value={editedAutoComplete ?? ""}
        onChange={(_, newValue) => {
          handleSteps(newValue);
          setEditedAutoComplete(newValue);
        }}
        onInputChange={(_, newValue) => {
          handleSteps(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="المدينة"
            variant="outlined"
            InputProps={{
              ...params.InputProps,
            }}
          />
        )}
        getOptionLabel={(option) => getOptionLabel(option)}
      />
    </div>
  );
};

export default HistoryAutoComplete;

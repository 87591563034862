import { Box } from "@mui/system";
import { Grid } from "@mui/material";
import { SnackbarProvider } from "notistack";
import { useRef } from "react";
import CallsCard from "./components/calls-card/calls-card";
import "./callers.css";

const Callers = ({
  calls,
  methods,
  methods2,
  methods3,
  methods4,
  methods5,
  methods6,
  methods7,
  methods8,
  methods9,
  methods10,
  methods11,
  methods12,
  methods13,
  methods14,
  methods15,
  methods80,
  methods81,
}: any) => {
  const formRef1 = useRef(null);
  const formRef2 = useRef(null);
  const formRef3 = useRef(null);
  const formRef4 = useRef(null);
  const formRef5 = useRef(null);
  const formRef6 = useRef(null);
  const formRef7 = useRef(null);
  const formRef8 = useRef(null);
  const formRef9 = useRef(null);
  const formRef10 = useRef(null);
  const formRef11 = useRef(null);
  const formRef12 = useRef(null);
  const formRef13 = useRef(null);
  const formRef14 = useRef(null);
  const formRef15 = useRef(null);
  const formRef80 = useRef(null);
  const formRef81 = useRef(null);

  const scrollToForm = (formRef) => {
    formRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="callers">
      <SnackbarProvider maxSnack={4}>
        <div className="bubble-calls-container">
          <Box
            className={`bubble-calls ${calls["1"] && "bubble-calls-active"} `}
            onClick={() => scrollToForm(formRef1)}
          >
            0
          </Box>
          <Box
            className={`bubble-calls ${calls["2"] && "bubble-calls-active"} `}
            onClick={() => scrollToForm(formRef2)}
          >
            1
          </Box>
          <Box
            className={`bubble-calls ${calls["3"] && "bubble-calls-active"} `}
            onClick={() => scrollToForm(formRef3)}
          >
            2
          </Box>
          <Box
            className={`bubble-calls ${calls["4"] && "bubble-calls-active"} `}
            onClick={() => scrollToForm(formRef4)}
          >
            3
          </Box>
          <Box
            className={`bubble-calls ${calls["5"] && "bubble-calls-active"} `}
            onClick={() => scrollToForm(formRef5)}
          >
            4
          </Box>
          <Box
            className={`bubble-calls ${calls["6"] && "bubble-calls-active"} `}
            onClick={() => scrollToForm(formRef6)}
          >
            5
          </Box>
          <Box
            className={`bubble-calls ${calls["7"] && "bubble-calls-active"} `}
            onClick={() => scrollToForm(formRef7)}
          >
            6
          </Box>
          <Box
            className={`bubble-calls ${calls["8"] && "bubble-calls-active"} `}
            onClick={() => scrollToForm(formRef8)}
          >
            7
          </Box>
          <Box
            className={`bubble-calls ${calls["9"] && "bubble-calls-active"} `}
            onClick={() => scrollToForm(formRef9)}
          >
            8
          </Box>
          <Box
            className={`bubble-calls ${calls["10"] && "bubble-calls-active"} `}
            onClick={() => scrollToForm(formRef10)}
          >
            10
          </Box>
          <Box
            className={`bubble-calls ${calls["11"] && "bubble-calls-active"} `}
            onClick={() => scrollToForm(formRef11)}
          >
            11
          </Box>
          <Box
            className={`bubble-calls ${calls["12"] && "bubble-calls-active"} `}
            onClick={() => scrollToForm(formRef12)}
          >
            12
          </Box>
          <Box
            className={`bubble-calls ${calls["13"] && "bubble-calls-active"} `}
            onClick={() => scrollToForm(formRef13)}
          >
            13
          </Box>
          <Box
            className={`bubble-calls ${calls["14"] && "bubble-calls-active"} `}
            onClick={() => scrollToForm(formRef14)}
          >
            14
          </Box>
          <Box
            className={`bubble-calls ${calls["15"] && "bubble-calls-active"} `}
            onClick={() => scrollToForm(formRef15)}
          >
            15
          </Box>
          <Box
            className={`bubble-calls ${calls["80"] && "bubble-calls-active"} `}
            onClick={() => scrollToForm(formRef80)}
          >
            80
          </Box>
          <Box
            className={`bubble-calls ${calls["81"] && "bubble-calls-active"} `}
            onClick={() => scrollToForm(formRef81)}
          >
            81
          </Box>
        </div>
        <Grid container spacing={6}>
          <CallsCard
            formRef={formRef1}
            call={calls["1"]}
            methods={methods}
            index={0}
            simNumber={"07508881115"}
          />
          <CallsCard
            formRef={formRef2}
            call={calls["2"]}
            methods={methods2}
            index={1}
            simNumber={"07728881115"}
          />
          <CallsCard
            formRef={formRef3}
            call={calls["3"]}
            methods={methods3}
            index={2}
            simNumber={"07858881115"}
          />
          <CallsCard
            formRef={formRef4}
            call={calls["4"]}
            methods={methods4}
            index={3}
            simNumber={"07713450777"}
          />
          <CallsCard
            formRef={formRef5}
            call={calls["5"]}
            methods={methods5}
            index={4}
            simNumber={"07508225529"}
          />
          <CallsCard
            formRef={formRef6}
            call={calls["6"]}
            methods={methods6}
            index={5}
          />
          <CallsCard
            formRef={formRef7}
            call={calls["7"]}
            methods={methods7}
            index={6}
            simNumber={"07728823838"}
          />
          <CallsCard
            formRef={formRef8}
            call={calls["8"]}
            methods={methods8}
            index={7}
            simNumber={"07505558889"}
          />
          <CallsCard
            formRef={formRef9}
            call={calls["9"]}
            methods={methods9}
            index={8}
            simNumber={""}
          />
          <CallsCard
            formRef={formRef10}
            call={calls["10"]}
            methods={methods10}
            index={10}
            simNumber={""}
          />
          <CallsCard
            formRef={formRef11}
            call={calls["11"]}
            methods={methods11}
            index={11}
            simNumber={""}
          />
          <CallsCard
            formRef={formRef12}
            call={calls["12"]}
            methods={methods12}
            index={12}
            simNumber={""}
          />
          <CallsCard
            formRef={formRef13}
            call={calls["13"]}
            methods={methods13}
            index={13}
            simNumber={""}
          />
          <CallsCard
            formRef={formRef14}
            call={calls["14"]}
            methods={methods14}
            index={14}
            simNumber={""}
          />
          <CallsCard
            formRef={formRef15}
            call={calls["15"]}
            methods={methods15}
            index={15}
            simNumber={""}
          />
          <CallsCard
            formRef={formRef80}
            call={calls["80"]}
            methods={methods80}
            index={80}
            simNumber={""}
          />
          <CallsCard
            formRef={formRef81}
            call={calls["81"]}
            methods={methods81}
            index={81}
            simNumber={""}
          />
        </Grid>
      </SnackbarProvider>
    </div>
  );
};

export default Callers;

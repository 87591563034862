import React from "react";

import { createTheme, ThemeProvider } from "@mui/material/styles";

import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient();

const myTheme = createTheme({
  direction: "rtl",
  typography: {
    fontFamily: `"Cairo", sans-serif`,
  },
  // add primary color
  palette: {
    primary: {
      main: "#475D92",
    },
    secondary: {
      main: "#BCD2EE",
    },
  },
});

import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { prefixer } from "stylis";
import AuthProvider from "../context/GlobalStateContext";
import { LicenseInfo } from "@mui/x-date-pickers-pro";

const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

function RTL(props: any) {
  return <CacheProvider value={cacheRtl}>{props.children}</CacheProvider>;
}

LicenseInfo.setLicenseKey(
  "43e9876fb05781bfa14900a61e79d2a7Tz1NVUktNjI5OTksRT0xMDI5NzAyMzY0NzU1NyxTPXByZW1pdW0sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

import * as Sentry from "@sentry/react";
import { SnackbarProvider } from "notistack";
import { BrowserRouter, Routes } from "react-router-dom";

Sentry.init({
  dsn: "https://65914c4dd21562310e9ec39028d2ac5e@o4506500666032128.ingest.sentry.io/4506500671078400",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

export const AppProviders = ({ children }: { children: React.ReactNode }) => {
  return (
    <React.StrictMode>
      <CacheProvider value={cacheRtl}>
        <ThemeProvider theme={myTheme}>
          <SnackbarProvider maxSnack={3}>
            <AuthProvider>
              <QueryClientProvider client={queryClient}>
                {children}
              </QueryClientProvider>
            </AuthProvider>
          </SnackbarProvider>
        </ThemeProvider>
      </CacheProvider>
    </React.StrictMode>
  );
};

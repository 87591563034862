import {
  Autocomplete,
  Box,
  Button,
  Fade,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from "@mui/material";
import {
  CustomAutoComplete,
  CustomTextField,
} from "../inputs/input/BootStrapInput";
import CustomDatePicker from "../inputs/custom-date-picker/custom-date-picker";
import ResponsiveDatePickers from "../inputs/custom-date-picker/static-date-picker";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { CustomSelect } from "../inputs/custom-select/custom-select";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import RegisterCallersSchema from "./schema/RegisterCallersSchema";
import { api } from "../../api/Intercepter";
import { useMutation, useQuery } from "react-query";
import { useSnackbar } from "notistack";
import RegisterAutoComplete from "./components/auto-complete/RegisterAutoComplete";
import { convertArabicToEnglishNumber } from "../../utils/convertArabicToEnglish";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import AdUnitsOutlinedIcon from "@mui/icons-material/AdUnitsOutlined";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import ManIcon from "@mui/icons-material/Man";
import WomanIcon from "@mui/icons-material/Woman";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import QuestionMarkOutlinedIcon from "@mui/icons-material/QuestionMarkOutlined";

type RegisterCallersTypes = {
  name: string;
  phone: string;
  date: string;
  episode: string;
  gender: string;
  location?: any;
};

const RegisterCallersForm = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [calender, setCalender] = useState(dayjs(new Date()));
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [location, setLocation] = useState<any>();
  const [genderInitialValue, setGenderInitialValue] = useState("none");
  const [episodeInitialValue, setEpisodeInitialValue] = useState("");
  const [regionValue, setRegionValue] = useState<any>({
    governorate_id: "",
    governorate_name: "",
    city_id: "",
    city_name: "",
    sub_city_id: "",
    sub_city_name: "",
    neighborhood_id: "",
    neighborhood_name: "",
  });

  const open = Boolean(anchorEl);

  const { data: episodesData } = useQuery(["episodes", calender], () =>
    api
      .get(`/episodes/date/${calender?.toISOString()}?relations[]=show`)
      .then((res) => res.data)
  );

  const {
    mutateAsync: createClientsMutate,
    isSuccess: isSuccessClientCreate,
    isError: isErrorClientCreate,
    error: errorClientCreate,
  } = useMutation(async ({ ...data }: any) => {
    let cleanedPhoneNumber = convertArabicToEnglishNumber(data?.phone).replace(
      /^(07|\+96407|96407)/,
      "+9647"
    );
    let cleanedPhoneNumberTo964 = cleanedPhoneNumber.replace(
      /^(\+964|964)/,
      "+964"
    );

    const res = await api.post("/calls/manual", {
      clientName: data.name,
      gender: data.gender === "unknown" ? null : data.gender,
      phoneNumber: cleanedPhoneNumberTo964,
      callDate: data.date,
      episodeId: data.episode,
      neighborhoodId: data?.location?.neighborhood_id,
      subCityId: data?.location?.sub_city_id,
      cityId: data?.location?.city_id,
      governorateId: data?.location?.governorate_id,
    });
    return res.data;
  });

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    trigger,
    control,
    formState: { errors },
  } = useForm<RegisterCallersTypes>({
    mode: "onChange",
    resolver: yupResolver(RegisterCallersSchema) as any,
  });

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onSubmit = async (data: RegisterCallersTypes) => {
    createClientsMutate({ ...data });
  };

  const handlePhoneNumberData = (phoneNumber?: string) => {
    let cleanedPhoneNumber = convertArabicToEnglishNumber(phoneNumber).replace(
      /^07/,
      "7"
    );
    cleanedPhoneNumber = cleanedPhoneNumber.replace(/^(\+964|964)/, "");

    api
      .get(
        `${
          import.meta.env.VITE_SOME_KEY_BASE_URL
        }/clients/phone/${cleanedPhoneNumber}`
      )
      .then((response) => {
        if (response?.data) {
          setValue("name", response?.data?.fullName);
          trigger("name");

          setValue("gender", response?.data?.gender ?? "none");
          setGenderInitialValue(response?.data?.gender ?? "none");
          setLocation(response?.data?.location);
          setEpisodeInitialValue("");
          const locationData =
            response?.data?.location[Object.keys(response?.data?.location)[0]];

          // if (locationData?.neighborhood_id) {

          setRegionValue({
            governorate_id: locationData?.governorate_id ?? "",
            governorate_name: locationData?.governorate_name ?? "",
            neighborhood_id: locationData?.neighborhood_id ?? "",
            neighborhood_name: locationData?.neighborhood_name ?? "",
            sub_city_id: locationData?.sub_city_id ?? "",
            sub_city_name: locationData?.sub_city_name ?? "",
            city_id: locationData?.city_id ?? "",
            city_name: locationData?.city_name ?? "",
          });
          setValue("location", {
            governorate_id: locationData?.governorate_id ?? undefined,
            governorate_name: locationData?.governorate_name ?? undefined,
            neighborhood_id: locationData?.neighborhood_id ?? undefined,
            neighborhood_name: locationData?.neighborhood_name ?? undefined,
            sub_city_id: locationData?.sub_city_id ?? undefined,
            sub_city_name: locationData?.sub_city_name ?? undefined,
            city_id: locationData?.city_id ?? undefined,
            city_name: locationData?.city_name ?? undefined,
          });
          // if (locationData?.neighborhood_id) {
          //   setRegionValue({
          //     neighborhood_id: locationData?.neighborhood_id,
          //     neighborhood_name: locationData?.neighborhood_name,
          //   });
          //   setValue("location", {
          //     neighborhood_id: locationData?.neighborhood_id,
          //     neighborhood_name: locationData?.neighborhood_name,
          //   });
          // } else if (locationData?.sub_city_id) {
          //   setValue("location", {
          //     sub_city_id: locationData?.sub_city_id,
          //     sub_city_name: locationData?.sub_city_name,
          //   });
          //   setRegionValue({
          //     sub_city_id: locationData?.sub_city_id,
          //     sub_city_name: locationData?.sub_city_name,
          //   });
          // } else if (locationData?.city_id) {
          //   setValue("location", {
          //     city_id: locationData?.city_id,
          //     city_name: locationData?.city_name,
          //   });
          //   setRegionValue({
          //     city_id: locationData?.city_id,
          //     city_name: locationData?.city_name,
          //   });
          // }
        }
      })
      .catch(() => {
        setValue("name", "");
        setRegionValue("");
        setValue("location", "");
        setValue("gender", "none");
        setGenderInitialValue("none");
        setLocation("");
        setEpisodeInitialValue("");
      });
  };

  useEffect(() => {
    setValue("date", dayjs(new Date()).format("YYYY-MM-DD"));
  }, []);

  useEffect(() => {
    if (isSuccessClientCreate) {
      enqueueSnackbar("تم انشاء المستخدم بنجاح", { variant: "success" });
      resetForm();
    }
  }, [isSuccessClientCreate]);

  useEffect(() => {
    if (isErrorClientCreate) {
      let errMsg = errorClientCreate as any;
      enqueueSnackbar(errMsg?.response?.data?.message[0], {
        variant: "error",
      });
    }
  }, [isErrorClientCreate]);

  const [options, setOptions] = useState([]);

  const handleSteps = (value: any) => {
    setOptions([]);
    api
      .get(
        `${
          import.meta.env.VITE_SOME_KEY_BASE_URL_LOCATION_MAKER
        }/auto-complete/${value}`
      )
      .then((response) => {
        if (response.status === 200) {
          setOptions(response?.data);
        }
      });
  };

  function formatLocation(...locationComponents) {
    const nonEmptyComponents = locationComponents
      .filter((component) => component)
      ?.reverse();

    return nonEmptyComponents.join(" / ");
  }

  function getOptionLabel(option) {
    let governorate_name = option?.governorate_name;
    let city_name = option?.city_name;
    let sub_city_name = option?.sub_city_name;
    let neighborhood_name = option?.neighborhood_name;

    const formattedLocation = formatLocation(
      governorate_name,
      city_name,
      sub_city_name,
      neighborhood_name
    );

    return formattedLocation;
  }

  const resetForm = () => {
    setValue("name", "");
    setValue("phone", "");
    setValue("date", dayjs(new Date())?.toISOString());
    setValue("episode", null);
    setEpisodeInitialValue(null);
    setValue("location", "");
    setValue("gender", "none");
    setGenderInitialValue("none");
    setRegionValue("");
    setCalender(dayjs(new Date()));
  };

  return (
    <Box
      sx={{
        maxWidth: "700px",
        minWidth: "300px",
        margin: "0px auto",
        backgroundColor: "#F7F9FF",
        padding: "40px 40px",
        border: "1px solid #DEE6FF",
        borderRadius: "12px",
        boxShadow: "0px 20px 40px 0px rgba(0,0,0,0.05)",
      }}
    >
      <FormControl sx={{ width: "100%" }}>
        {/* <FormLabel
          sx={{
            fontSize: "14px",
            fontWeight: "bold",
            color: "black",
            marginTop: "15px",
            marginBottom: "10px",
          }}
        >
          الرقم
        </FormLabel> */}
        <TextField
          placeholder="+9647704252732"
          {...register("phone")}
          label="رقم الموبايل"
          size="medium"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AdUnitsOutlinedIcon />
              </InputAdornment>
            ),
          }}
          error={!!errors?.phone}
          // className={errors?.phone?.message && "error"}
          onChange={(e: any) => {
            handlePhoneNumberData(e.target.value);
          }}
          sx={{
            ".muirtl-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input": {
              direction: "rtl",
              textAlign: "left",
            },
            ".muirtl-nugxp0-MuiInputBase-root-MuiOutlinedInput-root": {
              borderRadius: "12px",
            },
          }}
        />
        {errors?.phone && (
          <FormHelperText sx={{ color: "red" }}>
            {errors.phone?.message}
          </FormHelperText>
        )}
      </FormControl>
      <FormControl sx={{ width: "100%", marginTop: "15px" }}>
        {/* <FormLabel
          sx={{
            fontSize: "14px",
            fontWeight: "bold",
            color: "black",
            marginBottom: "10px",
          }}
        >
          الاسم
        </FormLabel> */}
        <Box sx={{ display: "flex", gap: "10px" }}>
          <Box sx={{ flexBasis: "50%" }}>
            <TextField
              label="الاسم"
              size="medium"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonOutlineOutlinedIcon />
                  </InputAdornment>
                ),
              }}
              sx={{
                textAlign: "left",
                ".muirtl-nugxp0-MuiInputBase-root-MuiOutlinedInput-root": {
                  borderRadius: "12px",
                },
                width: "100%",
              }}
              placeholder="الاسم"
              {...register("name")}
              error={!!errors?.name}
            />
            {errors?.name && (
              <FormHelperText sx={{ color: "red" }}>
                {errors.name?.message}
              </FormHelperText>
            )}
          </Box>
          <ToggleButtonGroup
            exclusive
            sx={{
              border: !!errors.gender?.message && "1px solid red",
              borderRadius: "12px",
              // ".muirtl-1dg0sm8-MuiToggleButtonGroup-root": {
              // }
              width: "100%",
              flexBasis: "50%",
            }}
            value={genderInitialValue}
          >
            <ToggleButton
              sx={{
                width: "100%",
                borderRadius: "12px",
                padding: "15px 0",
              }}
              {...register("gender", { required: true })}
              className={`${
                genderInitialValue === "male"
                  ? "gov-shortcut-btn-active"
                  : "gender-btn"
              }`}
              value={"male"}
              onClick={() => {
                setGenderInitialValue("male");
                setValue("gender", "male");
              }}
            >
              <ManIcon /> ذكر
            </ToggleButton>
            <ToggleButton
              sx={{
                width: "100%",
                borderRadius: "12px",
                padding: "15px 0",
              }}
              className={`${
                genderInitialValue === "female"
                  ? "gov-shortcut-btn-active"
                  : "gender-btn"
              }`}
              value={"female"}
              onClick={() => {
                setGenderInitialValue("female");
                setValue("gender", "female");
              }}
            >
              <WomanIcon /> انثى
            </ToggleButton>
            <ToggleButton
              sx={{
                width: "100%",
                borderRadius: "12px",
                padding: "15px 0",
              }}
              className={`${
                genderInitialValue === "none"
                  ? "gov-shortcut-btn-active"
                  : "gender-btn"
              }`}
              value={"none"}
              onClick={() => {
                setGenderInitialValue("none");
                setValue("gender", "none");
              }}
            >
              <QuestionMarkOutlinedIcon /> غير محدد
            </ToggleButton>
          </ToggleButtonGroup>
          {errors?.gender && (
            <FormHelperText sx={{ color: "red" }}>
              {errors.gender?.message}
            </FormHelperText>
          )}
        </Box>
      </FormControl>
      {/* <FormControl sx={{ width: "100%", marginTop: "15px" }}> */}
      {/* <FormLabel
          sx={{ fontSize: "14px", fontWeight: "bold", color: "black" }}
        >
          التاريخ
        </FormLabel> */}
      {/* <CustomDatePicker
          handleClick={handleClick}
          getValues={getValues}
          errors={errors}
          key={"date"}
          defaultValue={dayjs(new Date())}
        />
        {errors?.date && (
          <FormHelperText sx={{ color: "red" }}>
            {errors.date?.message}
          </FormHelperText>
        )}
        <Menu
          id="fade-menu"
          MenuListProps={{
            "aria-labelledby": "fade-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          TransitionComponent={Fade}
        >
          <ResponsiveDatePickers
            key={"date"}
            trigger={trigger}
            setValue={setValue}
            setCalender={(value) => setCalender(value)}
            errors={errors}
            handleClose={() => setAnchorEl(null)}
            defaultValue={getValues("date")}
          />
        </Menu> */}
      {/* </FormControl> */}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={["DatePicker"]}>
          <DatePicker
            sx={{
              width: "100%",
              marginTop: "10px",
              ".muirtl-120v7sr-MuiInputBase-root-MuiOutlinedInput-root": {
                borderRadius: "12px",
                flexDirection: "row-reverse",
              },
            }}
            defaultValue={dayjs(getValues("date"))}
            label="التاريخ"
            onChange={(e: any) => {
              setValue("date", e);
              trigger("date");
              setCalender(e);
            }}
          />
        </DemoContainer>
      </LocalizationProvider>
      <FormControl sx={{ width: "100%", marginTop: "20px" }}>
        {/* <FormLabel
          sx={{
            fontSize: "14px",
            fontWeight: "bold",
            color: "black !important",
          }}
        >
          اختر البرنامج
        </FormLabel> */}
        <InputLabel id="demo-simple-select-helper-label">
          اختر البرنامج
        </InputLabel>
        <Select
          labelId="demo-simple-select-helper-label"
          // id="demo-simple-select-helper"
          defaultValue={""}
          variant="outlined"
          label="اختر البرنامج"
          placeholder="اختر البرنامج"
          {...register("episode")}
          onChange={(e: any) => {
            setValue("episode", e.target.value);
            trigger("episode");
            setEpisodeInitialValue(e.target.value);
          }}
          error={!!errors?.episode}
          value={episodeInitialValue}
          sx={{
            borderRadius: "12px",
          }}
        >
          <MenuItem value={""} disabled>
            لايوجد
          </MenuItem>
          <MenuItem value={6122}>متفرقة</MenuItem>
          {episodesData?.length > 0 &&
            episodesData?.map((item: any) => (
              <MenuItem value={item?.id} key={item?.id}>
                {item?.show?.radioShowName}
              </MenuItem>
            ))}
        </Select>
        {errors?.episode && (
          <FormHelperText sx={{ color: "red" }}>
            {errors.episode?.message}
          </FormHelperText>
        )}
      </FormControl>
      <FormControl sx={{ marginTop: "20px", width: "100%" }}>
        <Controller
          name={`location`}
          control={control}
          render={({ field }) => (
            <Autocomplete
              // sx={{
              //   marginTop: "7px",
              //   border: "1px solid #E7EBF0",
              //   borderRadius: "6px",
              //   width: "100%",
              //   ".MuiOutlinedInput-root.MuiInputBase-sizeSmall": {
              //     backgroundColor: "#F7F7F8",
              //   },
              // }}
              sx={{
                ".MuiOutlinedInput-root": {
                  borderRadius: "12px",
                },
              }}
              {...field}
              size="medium"
              // disablePortal
              options={options ?? []}
              value={regionValue}
              onChange={(_, newValue: any) => {
                setRegionValue(newValue);
                // field.onChange(newValue);
                handleSteps(newValue);
                setRegionValue({
                  governorate_id: newValue?.governorate_id ?? undefined,
                  governorate_name: newValue?.governorate_name ?? undefined,
                  city_id: newValue?.city_id ?? undefined,
                  city_name: newValue?.city_name ?? undefined,
                  sub_city_id: newValue?.sub_city_id ?? undefined,
                  sub_city_name: newValue?.sub_city_name ?? undefined,
                  neighborhood_id: newValue?.neighborhood_id ?? undefined,
                  neighborhood_name: newValue?.neighborhood_name ?? undefined,
                });
                setValue("location", {
                  governorate_id: newValue?.governorate_id ?? undefined,
                  governorate_name: newValue?.governorate_name ?? undefined,
                  city_id: newValue?.city_id ?? undefined,
                  city_name: newValue?.city_name ?? undefined,
                  sub_city_id: newValue?.sub_city_id ?? undefined,
                  sub_city_name: newValue?.sub_city_name ?? undefined,
                  neighborhood_id: newValue?.neighborhood_id ?? undefined,
                  neighborhood_name: newValue?.neighborhood_name ?? undefined,
                });
                // setRegionType("neighborhood");
              }}
              onInputChange={(_, newValue: any) => {
                // field.onChange(newValue);
                // if (user) {
                //   setUser({
                //     ...user,
                //     city: {
                //       ...user.city,
                //       cityName: newValue,
                //     },
                //     region: {
                //       ...user.region,
                //       regionName: newValue,
                //     },
                //   });
                // } else {
                // field.onChange(newValue);
                // }

                handleSteps(newValue);
              }}
              // onOpen={() => setOpen(true)}
              onClose={(event, reason: any) => {
                // if (reason === "blur" || reason === "toggleInput") {
                //   setOpen(false);
                // }
                if (reason === "select-option") {
                  // event.preventDefault();
                }
              }}
              // open={open}
              getOptionLabel={getOptionLabel}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="المنطقة"
                  fullWidth
                  label="المدينة"
                  variant="outlined"
                  error={!!errors[`location`]}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <FmdGoodOutlinedIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          )}
        />
      </FormControl>
      <FormControl sx={{ marginTop: "20px" }}>
        {/* <Controller
          name={`gender`}
          control={control}
          defaultValue={genderInitialValue}
          // defaultValue="female"
          {...register(`gender`, { required: true })}
          render={({ field }) => (
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              {...field}
            >
              <FormControlLabel
                value="female"
                control={
                  <Radio
                    icon={
                      <WomanIcon
                        sx={{
                          backgroundColor: "#FBF8FB",
                          color: "#BF62C1",
                          borderRadius: "4px",
                          border: "1px solid #BF62C1",
                        }}
                      />
                    }
                    checkedIcon={
                      <WomanIcon
                        sx={{
                          backgroundColor: "#BF62C1",
                          color: "white",
                          borderRadius: "4px",
                        }}
                      />
                    }
                  />
                }
                label="انثى"
              />
              <FormControlLabel
                value="male"
                control={
                  <Radio
                    icon={
                      <ManIcon
                        sx={{
                          backgroundColor: "#F8F9FB",
                          color: "#629FC1",
                          borderRadius: "4px",
                          border: "1px solid #629FC1",
                        }}
                      />
                    }
                    checkedIcon={
                      <ManIcon
                        sx={{
                          backgroundColor: "#629FC1",
                          color: "white",
                          borderRadius: "4px",
                        }}
                      />
                    }
                  />
                }
                label="ذكر"
              />
              <FormControlLabel
                value={"unknown"}
                control={
                  <Radio
                    icon={
                      <HelpCenterIcon
                        sx={{
                          backgroundColor: "#555555",
                          color: "white",
                          borderRadius: "4px",
                          // border: "1px solid #BF62C1",
                        }}
                      />
                    }
                    checkedIcon={
                      <HelpCenterIcon
                        sx={{
                          backgroundColor: "white",
                          color: "#555555",
                          borderRadius: "4px",
                        }}
                      />
                    }
                  />
                }
                label="غير محدد"
              />
            </RadioGroup>
          )}
        /> */}
      </FormControl>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <Button
          onClick={handleSubmit(onSubmit)}
          sx={{
            marginTop: "20px",
            width: "100%",
            background: "white",
            fontSize: "16px",
            padding: "6px 40px",
            color: "white",
            borderRadius: "12px",
            backgroundColor: "#475D92",
            "&:hover": {
              backgroundColor: "#475D92",
              color: "white",
            },
          }}
        >
          حفظ
        </Button>
        <Tooltip title="اعادة تعيين">
          <IconButton
            sx={{
              backgroundColor: "#D9E2FF",
              borderRadius: "50%",
              padding: "5px !important",
              height: "45px",
              width: "45px",
              marginTop: "20px",
            }}
            onClick={resetForm}
          >
            <RestartAltOutlinedIcon
              sx={{
                color: "#475D92",
              }}
            />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default RegisterCallersForm;

import {
  Autocomplete,
  FormControl,
  InputAdornment,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";
import DoneIcon from "@mui/icons-material/Done";
import { useState } from "react";
import { api } from "../../../../api/Intercepter";

const CallsFormRegionField = ({
  governorateIdParams,
  setGovernorateIdParams,
  autoCompleteData,
  setAutoCompleteData,
  register,
  errors,
  setValue,
}) => {
  const [options, setOptions] = useState([]);

  const handleSteps = (value: any) => {
    setOptions([]);
    api
      .get(
        `${
          import.meta.env.VITE_SOME_KEY_BASE_URL_LOCATION_MAKER
        }/auto-complete/${value}`,
        {
          params: {
            governorate_id: governorateIdParams ?? undefined,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          setOptions(response?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function formatLocation(...locationComponents) {
    const nonEmptyComponents = locationComponents
      .filter((component) => component)
      ?.reverse();

    return nonEmptyComponents.join(" / ");
  }

  function getOptionLabel(option) {
    let governorate_name = option?.governorate_name;
    let city_name = option?.city_name;
    let sub_city_name = option?.sub_city_name;
    let neighborhood_name = option?.neighborhood_name;

    const formattedLocation = formatLocation(
      governorate_name,
      city_name,
      sub_city_name,
      neighborhood_name
    );

    return formattedLocation;
  }

  return (
    <>
      <ToggleButtonGroup
        value={governorateIdParams}
        exclusive
        className="calls-card-form-gov-shortcut"
      >
        <ToggleButton
          className={`calls-card-form-gov-shortcut-btn ${
            governorateIdParams === 16 && "gov-shortcut-btn-active"
          }`}
          onClick={() => setGovernorateIdParams(16)}
          value={16}
        >
          {governorateIdParams === 16 && <DoneIcon sx={{ fontSize: "20px" }} />}
          صلاح الدين
        </ToggleButton>
        <ToggleButton
          className={`calls-card-form-gov-shortcut-btn ${
            governorateIdParams === 3 && "gov-shortcut-btn-active"
          }`}
          onClick={() => setGovernorateIdParams(3)}
          value={3}
        >
          {governorateIdParams === 3 && <DoneIcon sx={{ fontSize: "20px" }} />}
          نينوى
        </ToggleButton>
        <ToggleButton
          className={`calls-card-form-gov-shortcut-btn ${
            governorateIdParams === 19 && "gov-shortcut-btn-active"
          }`}
          onClick={() => setGovernorateIdParams(19)}
          value={19}
        >
          {governorateIdParams === 19 && <DoneIcon sx={{ fontSize: "20px" }} />}
          الانبار
        </ToggleButton>
      </ToggleButtonGroup>
      <FormControl sx={{ width: "100%", marginTop: "20px" }}>
        <Autocomplete
          {...register("city", { required: true })}
          options={options}
          sx={{
            ".muirtl-wcmekw-MuiInputBase-root-MuiOutlinedInput-root": {
              borderRadius: "12px",
            },
          }}
          value={autoCompleteData ?? ""}
          getOptionLabel={getOptionLabel}
          onChange={(event, newValue) => {
            setValue("city", newValue);
            setAutoCompleteData(newValue);
            handleSteps(newValue);
          }}
          onInputChange={(event, newInputValue) => {
            handleSteps(newInputValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              error={!!errors[`city`]}
              label="المنطقة"
              placeholder="المنطقة"
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <FmdGoodOutlinedIcon />
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </FormControl>
      <FormControl sx={{ width: "100%", marginTop: "20px" }}>
        <TextField
          placeholder="الملاحظات"
          label="الملاحظات"
          size="medium"
          multiline
          {...register("info")}
          rows={3}
          sx={{
            ".muirtl-1mvfrdb-MuiInputBase-root-MuiOutlinedInput-root": {
              borderRadius: "12px",
            },
            ".muirtl-i4bv87-MuiSvgIcon-root": {
              marginTop: "-55px",
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <TextSnippetOutlinedIcon />
              </InputAdornment>
            ),
          }}
        />
      </FormControl>
    </>
  );
};

export default CallsFormRegionField;
